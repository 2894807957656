import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { finalize, map } from 'rxjs/operators';
import { inicio, link_vivo } from "../interfaz/interface-fire";
import { AngularFireStorage } from '@angular/fire/storage';
import { SqlService } from './sql.service';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class FireService {
  url_image:string
  
  constructor(
    private db:AngularFirestore,
    private storage: AngularFireStorage,
    private sqlService:SqlService,
    private toastController:ToastController,
    private alertController:AlertController
  ) { }

  obtenerEstudios(){
    return  this.db.collection<inicio>('Principal', ref => ref.orderBy('orden','asc')).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;         
          return {id, ...data};
        });
      })
    );
  }

  obtenerInicio(){
    return  this.db.collection<inicio>('Principal', ref => ref.orderBy('orden','asc')).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;         
          return {id, ...data};
        });
      })
    );
  }

  obtenerLinkVivo(){
    return  this.db.collection<link_vivo>('Link_vivo').snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;         
          return {id, ...data};
        });
      })
    );
  }

    cargarIMG(fileName:string, file:any, base?:any): Promise<string> {
    // if(!base){
    //   console.log("cargaremos imagen 1")
    //   return new Promise<any>((resolve, reject) => {
    //   const task =  this.storage.upload(fileName, file);
    //   task.snapshotChanges().pipe(
    //           finalize(() => fileRef.getDownloadURL().subscribe(
    //               res => resolve( this.url_image = res),
    //               err => reject(err)
    //           ))).subscribe();
    //   })
    // }else{
      console.log("cargaremos imagen 2")
      return new Promise<string>((resolve, reject) => {
        // Crea una referencia a la ubicación en Firebase Storage
        const fileRef = this.storage.ref(fileName);
  
        // Carga el archivo a Firebase Storage
        const task = this.storage.upload(fileName, base);
      task.snapshotChanges().pipe(
        // Finaliza el observable cuando la carga se completa
        finalize(() => {
          // Obtiene la URL de descarga del archivo
          fileRef.getDownloadURL().subscribe(
            url => {
              // Devuelve la URL de descarga
              console.log("devolmamos url " + url)
              resolve(url);
            },
            error => {
              console.log("error url " +  error)

              // Maneja cualquier error al obtener la URL
              reject(error);
            }
          );
        })
      
      ).subscribe();
      })
  }

  obtenerURL(fileName){
     return this.url_image
  }

  async eliminarIMG(filename, id_generico, alerta?){
    if(!alert){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirmar acción',
      message: 'Tu imagen se borrara permanentemente de la base de datos, ¿deseas continuar?.',
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
                    
          }
        }, {
          text: 'SI',
          handler: () => {
            this.storage.ref(filename).delete().subscribe(res=>{
              this.presentToast("Imagen eliminada de FIREBASE")

              let data:any=[]
    
              data.push({
                "sp":"sp_principal",
                "@operacion":"D",
                "@sub_operacion":"I",
                "@id_generico":id_generico
                
                 })
                this.sqlService.postData(data,"values").subscribe(data=>{                  
                  this.presentToast("Imagen eliminada de SQL server")
                })  
            }) 
          }
        }
      ]
    });
    await alert.present();
    }else{
      this.storage.ref(filename).delete().subscribe(res=>{
        this.presentToast("Imagen eliminada de FIREBASE")

        let data:any=[]

        data.push({
          "sp":"sp_principal",
          "@operacion":"D",
          "@sub_operacion":"I",
          "@id_generico":id_generico
          
           })
          this.sqlService.postData(data,"values").subscribe(data=>{                  
            this.presentToast("Imagen eliminada de SQL server")
          })  
      }) 
    }
  }

  eliminarFirebase(path){
    this.storage.ref(path).delete().subscribe(res=>{
    
    })
  }

  async presentToast(texto) {
    const toast = await this.toastController.create({
      message: texto,
      duration: 2000
    });
    toast.present();
  }

}
