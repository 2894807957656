// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyDeatEHcPUayz-vn5kZ1sv1FEuKXUBSjFw",
    authDomain: "rf-db-eaa95.firebaseapp.com",
    databaseURL: "https://rf-db-eaa95.firebaseio.com",
    projectId: "rf-db-eaa95",
    storageBucket: "rf-db-eaa95.appspot.com",
    messagingSenderId: "457370032310",
    appId: "1:457370032310:web:6cb7158992a2a468a0ee17",
    measurementId: "G-4MJ2FTCX2N",
    vapidKey:'BGzaCvACU-yN-DBFlENUIigOsYcpNsukVavQhe9hZPLrJn46m0rYKcLgUJhblGUVF6Qfz0RmhoFnwYtZdJFEqJI'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
