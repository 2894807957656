import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'actividades',
    pathMatch: 'full'
  },
  {
    path: 'folder',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'predicas',
    loadChildren: () => import('./pages/predicas/predicas.module').then( m => m.PredicasPageModule)
  },
  {
    path: 'vivo',
    loadChildren: () => import('./pages/vivo/vivo.module').then( m => m.VivoPageModule)
  },
  {
    path: 'inicio',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'img-inicio',
    loadChildren: () => import('./administrador/img-inicio/img-inicio.module').then( m => m.ImgInicioPageModule)
  },
  {
    path: 'admin-principal',
    loadChildren: () => import('./administrador/admin-principal/admin-principal.module').then( m => m.AdminPrincipalPageModule)
  },
  {
    path: 'predicas-admin/:Id_predicas',
    loadChildren: () => import('./administrador/predicas-admin/predicas-admin.module').then( m => m.PredicasAdminPageModule)
  },
  {
    path: 'biblia',
    loadChildren: () => import('./pages/biblia/biblia.module').then( m => m.BibliaPageModule)
  },
  {
    path: 'creacion/:uso',
    loadChildren: () => import('./usuarios/creacion/creacion.module').then( m => m.CreacionPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./usuarios/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./usuarios/perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'vista-predicas/:id_departamento/:nombre/:url_imagen',
    loadChildren: () => import('./vistas/usuarios/vista-predicas/vista-predicas.module').then( m => m.VistaPredicasPageModule)
  },
  {
    path: 'historia/:id_actividad/:titulo/:url_imagen/:casado/:inscrito/:inscripcion',
    loadChildren: () => import('./pages/historia/historia.module').then( m => m.HistoriaPageModule)
  },
  {
    path: 'estudios',
    loadChildren: () => import('./pages/estudios/estudios.module').then( m => m.EstudiosPageModule)
  },
  {
    path: 'actividades',
    loadChildren: () => import('./pages/actividades/actividades.module').then( m => m.ActividadesPageModule)
  },
  {
    path: 'mensajes',
    loadChildren: () => import('./pages/mensajes/mensajes.module').then( m => m.MensajesPageModule)
  },
  {
    path: 'peticiones-oracion',
    loadChildren: () => import('./pages/peticiones-oracion/peticiones-oracion.module').then( m => m.PeticionesOracionPageModule)
  },
  {
    path: 'peticiones-pendientes',
    loadChildren: () => import('./administrador/peticiones-pendientes/peticiones-pendientes.module').then( m => m.PeticionesPendientesPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./componentes/auxiliares/help/help.module').then( m => m.HelpPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
