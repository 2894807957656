import { Component, OnInit } from '@angular/core';

import { AlertController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { SqlliteService } from './servicios/sqllite.service';
// import { FCM } from '@ionic-native/fcm/ngx';

import { GeneralService } from './servicios/general.service';
// import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic"
import { UsuarioService } from './servicios/usuario.service';
import { NotificationsService } from './servicios/notifications.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  Bienvenida = ""
  rol:number = 2
  autorizo:boolean 
  public en_vivo

  public appPages = [
    // {
    //   title: 'Inicio',
    //   url: '/folder/inicio',
    //   icon: 'home'
    // },
    {
      title: 'Inicio',
      url: '/actividades',
      icon: 'megaphone'
     },
    {
      title: 'Mi Perfil',
      url: '/login',
      icon: 'person'
    },
    // {
    //   title: 'Departamentos',
    //   url: '/estudios',
    //   icon: 'clipboard'
    // },
    // {
    //   title: 'Intercesión',
    //   url: '/peticiones-oracion',
    //   icon: 'information'
    // },
    {
      title: 'Nosotros',
      url: '/vivo',
      icon: 'today'
    },
    // {
    //   title: 'Oración sabado 6:30 AM',
    //   url: '/Nextcloud/index.php/call/9cuf25da',
    //   icon: 'alarm'
    // },
    // {
    //   title: 'Discipulado virtual',
    //   url: '/Nextcloud/index.php/call/fkyqqy6v',
    //   icon: 'book'
    // }
    // ,
    // {
    //   title: 'Alabanza',
    //   url: '/peticiones-oracion',
    //   icon: 'musical-note'
    // }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private dom: DomSanitizer,
    private sqlliteservice:SqlliteService,
    private gralService:GeneralService,
    // private fcm: FCM,
    private nav:NavController,
    private userService:UsuarioService,
    private alertController:AlertController,
    private notificationsService:NotificationsService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      //this.configurar_notificaciones()
    });
  } 

  ngOnInit() {
 //   this.gralService.obtenerApiSQL()
    // if  ( this.platform.is('pwa')|| this.platform.is('mobileweb') || this.platform.is('desktop')){

    // }else{
      this.sqlliteservice.select_usuario().then(()=>{
        this.datosInicio(this.sqlliteservice.usuario,this.sqlliteservice.rol)
       // this.notificationsService.init();

      })
      const path = window.location.pathname.split('actividades')[1];
  // }
}



  
  datosInicio(usuario, rol){
    if(usuario){
      this.Bienvenida =  usuario
      this.gralService.analitica('Ingreso', {"el usuario es este":usuario})

    }else{
      this.Bienvenida = ""
      this.gralService.analitica("Ingreso",{'Sin usuario':0})
    }
      if(rol == 1){
          this.appPages.push(
            {
              title: 'Administración',
              url: '/admin-principal',
              icon: 'person'
        })
      }
      if(rol == 3){
        // FCM.subscribeToTopic('intercesion')
        this.appPages.push(
          {
            title: 'Peticiones de pendientes',
            url: '/peticiones-pendientes',
            icon: 'folder-open'
      })
    }
  }

  calificacion(){
    let usuario = this.sqlliteservice.usuario
    this.gralService.analitica('calificacion',{usuario:2})
    if(this.platform.is("android")){
      this.gralService.abrirDocumento("https://play.google.com/store/apps/details?id=com.restfam.luisaguirre.app_rf")
    }else if(this.platform.is("ios")){
      this.gralService.abrirDocumento("https://apps.apple.com/gt/app/iglesia-restauraci%C3%B3n-familiar/id1560089106")
    }
  }
  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Tu calificación es importante!',
      message: 'El calificar una aplicacion, permite mejorar la posición de la misma en la tienda oficial.',
      buttons: [
        {
          text: 'No por ahora',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Quiero calificar',
          handler: () => {
            this.calificacion();
          }
        }
      ]
    });

    await alert.present();
  }
}
