import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { retry, catchError, finalize } from 'rxjs/operators';
import { Observable, throwError, from } from 'rxjs';
import { LoadingController, Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SqlService {
  //base_path = 'https://localhost:44393/api/';
  //base_path = 'https://appservicerf.azurewebsites.net/api/';
  base_path:string = ""
  //this.base_path = 'http://185.118.164.120:80/api/'

 // base_path:string = '';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(
    private http: HttpClient,
    private platform:Platform,
    private loadingController:LoadingController
  ) { }



   postData(item, nombre_controller?) {
    console.log(this.base_path + "esta es la base ")
    if((!this.base_path)){
      //alert("problema con API")
      this.base_path = "https://84i4kx1zdb.execute-api.us-east-2.amazonaws.com/produccion/api_cq/"
    }
     return this.http
      .post(this.base_path,JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
      
  }

  getDataId(id){
    return this.http
      .get(this.base_path + '/' + id)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  async openLoader(msg) {
    const loading = await this.loadingController.create({
      message: 'Por favor espere ... ' + msg,
      // duration: 2000
    });
    await loading.present();
  }

  async closeLoading() {
    return await this.loadingController.dismiss();
  }


}
