import {Injectable} from '@angular/core';
import {firebase} from '@firebase/app';
import '@firebase/messaging';
import {environment} from '../../environments/environment';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { UsuarioService } from './usuario.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private afMessaging: AngularFireMessaging,
    private userService:UsuarioService,
  ) { }
init(): Promise<void> {
  console.log(0,0)
    return new Promise<void>((resolve, reject) => {
      console.log(0)
        navigator.serviceWorker.ready.then((registration) => {
            // Don't crash an error if messaging not supported
            console.log(1)
            if (!firebase.messaging.isSupported()) {
              console.log(2)
                   resolve();
                   return;
            }
            console.log(3)

            const messaging = firebase.messaging();

            // Register the Service Worker
            messaging.useServiceWorker(registration);
            console.log(4)

            // Initialize your VAPI key
            messaging.usePublicVapidKey(
                  environment.firebaseConfig.vapidKey
                  
            );
            console.log(5)

            // Optional and not covered in the article
            // Listen to messages when your app is in the foreground
            messaging.onMessage((payload) => {
                console.log('payload' + payload);
            });
            console.log(6)
            // Optional and not covered in the article
            // Handle token refresh
            messaging.onTokenRefresh(() => {
                messaging.getToken().then(
                (refreshedToken: string) => {
                    console.log('refreshedToken' + refreshedToken);
                }).catch((err) => {
                    console.error(err);
                });
            });

            resolve();
        }, (err) => {
          console.log('dio error')
            reject(err);
        });
    });
  }

  requestPermission(): Promise<void> {
    return new Promise<void>(async (resolve) => {
        if (!Notification) {
            resolve();
            return;
        }
        if (!firebase.messaging.isSupported()) {
            resolve();
            return;
        }
        try {
            const messaging = firebase.messaging();
            await messaging.requestPermission();

            const token: string = await messaging.getToken();

           // console.log('User notifications token:', token);

          

        } catch (err) {
            // No notifications granted
        }

        resolve();
    });
}

abrirNotificacion(){
}


listen(ingreso?) {
  if(ingreso){
    this.afMessaging.requestToken // getting tokens
    .subscribe(
      (token) => { // USER-REQUESTED-TOKEN
        console.log('Mandamos a actualizar nuevo ingreso ', token);
        this.userService.actualizarTokenUsuario(token)
      })
  }else{
    this.afMessaging.requestToken // getting tokens
    .subscribe(
      (token) => { // USER-REQUESTED-TOKEN
        console.log('Permission granted! Save to the server!', token);
        if(localStorage.getItem('token') !== token){
          this.userService.subscribirTopic(token,'general');
          localStorage.setItem('topic_general', 's' );
          localStorage.setItem('token', token );
          this.userService.actualizarTokenUsuario(token)
          console.log("se actualizo el token")
        }
      },
      (error) => {
        console.error(error);
      }
  );
  console.log("escuchamos")
  this.afMessaging.messages
    .subscribe((message) => { console.log('ok --> '+message); });
  }
}


subscribeToTopic(token) {
  console.log("sucribimos a topic")
  const messaging = firebase.messaging();
  

  // messaging.getToken({ vapidKey: 'BGzaCvACU-yN-DBFlENUIigOsYcpNsukVavQhe9hZPLrJn46m0rYKcLgUJhblGUVF6Qfz0RmhoFnwYtZdJFEqJI' }).then((currentToken) => {
    if (token) {
      return fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/todos_los_usuarios', {
        method: 'POST',
        headers: {
          'Authorization': 'AAAAan1g2LY:APA91bGebFX6WcCz6S7iLYMUY0Jw71HIyBcOzIfYAFQnDm9e0vg-b9v9jHCuI4koL-SyYg1eNPbo6rqdWAcFaKRD3jfN8jvpCfa3hBuMNts_IaUhFXgr_L_D7vK4uijyfRcKV3CmnDQE'
        }
      });
    } else {
      console.log('No se pudo obtener el token.');
    }
  // }).then(() => {
  //   console.log('Usuario suscrito al tópico exitosamente.');
  // }).catch((error) => {
  //   console.error('Error al suscribir al usuario al tópico:', error);
  // });
}


}