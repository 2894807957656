import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { NavController, Platform } from '@ionic/angular';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class SqlliteService {
  private storage: SQLiteObject;

  public IdMiembro;
  public usuario;
  public urlimage;
  public rol;

  constructor(
    private sqlite: SQLite,
    private sqlPorter: SQLitePorter,
    private platform: Platform,
    private nav:NavController,
    private gralService:GeneralService
    ) { }

  crear_db(rol?, IdMiembro?, usuario?, urlimage?){
    //this.delete_usuario()
    return
    // this.sqlite.create({
    //   name: 'db_usuario.db',
    //   location: 'default'
    // })
    // .then((db: SQLiteObject) => {
    //     this.storage = db;
    //       this.crear_tabla(rol, IdMiembro, usuario, urlimage);

    // });
  }

  async valida_db(rol?, IdMiembro?, usuario?, urlimage?){
    return
  //   console.log("valida db 1")
  //   try{
  //   await this.sqlite.create({
  //     name: 'db_usuario.db',
  //     location: 'default'
  //   })
  //   .then((db: SQLiteObject) => {
  //       this.storage = db;
  //       console.log("valida db 2")
  //   });
  // }catch(error){
  //   alert("error 2 " + error)
  //   //return
  // }
  }


  crear_tabla(rol, IdMiembro, usuario, urlimage){
    // this.storage.executeSql(
    //   'create table Login(rol int null, IdMiembro int null, usuario varchar(100) null, urlimage varchar(500) null)', [])
    // .then(() => {
      this.insert_usuario(rol, IdMiembro, usuario, urlimage)
    // })
    // .catch(e => console.log(e));
  }

  async insert_usuario(rol, IdMiembro, usuario, urlimage) {
    let data = [rol, IdMiembro, usuario, urlimage];
    this.rol = rol
    this.IdMiembro = IdMiembro
    this.usuario = usuario
    this.urlimage = urlimage

   await localStorage.setItem('rol', rol);
   await localStorage.setItem('IdMiembro', IdMiembro);
   await localStorage.setItem('usuario', usuario);
   await localStorage.setItem('urlimage', urlimage);

   //this.gralService.presentAlert("Tu ingreso ha sido exitoso",'','')
   this.nav.navigateRoot("/perfil")

    // return this.storage.executeSql('INSERT INTO Login (rol, IdMiembro, usuario, urlimage) VALUES (?, ?, ?, ?)', data).then(data => {
    //   this.gralService.presentAlert("Tu ingreso ha sido exitoso",'','')
    //   this.nav.navigateRoot("/perfil")
    // });
  }

  async select_usuario() {
    try{
    this.IdMiembro = localStorage.getItem('IdMiembro');
    this.usuario = localStorage.getItem('usuario')
    this.urlimage = localStorage.getItem('urlimage')
    this.rol = localStorage.getItem('rol')
    return 0
    }catch (error){
      alert(error)
    }

    // try {
    //  await  this.valida_db()
    //   return await this.storage.executeSql('SELECT * FROM Login', []).then(data => {
    //     if (data.rows.item(0).IdMiembro != '') {
    //       this.IdMiembro = data.rows.item(0).IdMiembro;
    //       this.usuario = data.rows.item(0).usuario;
    //       this.urlimage = data.rows.item(0).urlimage;
    //       this.rol = data.rows.item(0).rol
    //       return 1
    //     }else{
    //       this.rol = ""
    //       this.IdMiembro = "";
    //       this.usuario = "";
    //       this.urlimage = ""
    //       return 0
    //     }
    //   });
    // } catch (error) {
    //   alert(error)
    //   this.rol = ""
    //   this.IdMiembro = "";
    //   this.usuario = "";
    //   this.urlimage = ""
    //   return 0
    // }
  }

 async delete_usuario(){
    await localStorage.removeItem('IdMiembro')
    await localStorage.removeItem('usuario')
    await localStorage.removeItem('rol')
    await localStorage.removeItem('IdMiembro')
    await localStorage.removeItem('usuario')
    await localStorage.removeItem('urlimage')
    localStorage.removeItem('token')
    localStorage.removeItem('topic_general')

    // this.sqlite.deleteDatabase({
    //   name: 'db_usuario.db',
    //   location: 'default'
    // }).then (()=>{
    //     this.IdMiembro = "";
    //     this.usuario = "";
    //     this.rol = ""
    //     this.IdMiembro = "";
    //     this.usuario = "";
    //     this.urlimage = ""
    //     return 0
    // })
  }

  logout(){
    this.rol = ""
    this.IdMiembro = "";
    this.usuario = "";
    this.urlimage = ""
    this.delete_usuario()
    
    this.gralService.presentAlert("Has cerrado sesión","Tu sesión ha finalizado correctamente","")
    this.nav.navigateRoot("/login")
  }
}
