import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToastController, AlertController, NavController, Platform } from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import {formatDate } from '@angular/common';
import { LoadingController } from '@ionic/angular';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { FireService } from './fire.service';
import { SqlService } from './sql.service';
import { AngularFireFunctions } from "@angular/fire/functions";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  browser
  biblia
  imageResponse: any;
  opcion: any;
  photos: SafeResourceUrl
  prefijo_img:string = 'data:image/jpeg;base64,'
  public en_vivo
  img //para cargar imagenes obtener la url
  


  options : InAppBrowserOptions = {
    location : 'yes',//Or 'no' 
    hidden : 'yes', //Or  'yes'
    clearcache : 'yes',
    clearsessioncache : 'yes',
    zoom : 'no',//Android only ,shows browser zoom controls 
    hardwareback : 'yes',
    mediaPlaybackRequiresUserAction : 'no',
    shouldPauseOnSuspend : 'no', //Android only 
    closebuttoncaption : 'Close', //iOS only
    disallowoverscroll : 'yes', //iOS only 
    toolbar : 'no', //iOS only 
    enableViewportScale : 'no', //iOS only 
    allowInlineMediaPlayback : 'no',//iOS only 
    presentationstyle : 'pagesheet',//iOS only 
    fullscreen : 'yes',//Windows only    
};
  constructor(
    private dom: DomSanitizer,
    private alertController:AlertController,
    public camera: Camera,
    public loadingController: LoadingController,
    private toastController: ToastController,
    private iab: InAppBrowser,
    private imagePicker: ImagePicker,
    private fireservice:FireService,
    private nav:NavController,
    private sqlService:SqlService,
    private fun:AngularFireFunctions,
    private http: HttpClient,
    private platform:Platform,
    private fireAnalitics:AngularFireAnalytics
  ) { }

analitica(pagina:string, param:{}){
  this.fireAnalitics.logEvent(pagina, param)
}

   cargarImageDataBrowser(e){
    if(e.target.files.length > 1){
      for (let i = 0; i < e.target.files.length; i++) {
        if(e.target.files[i].size > 2000000){
          this.presentAlert('Imagen muy pesada',"La imagen es mayor a 2 MB","Le recomendamos descargar de su pagina de facebook la imagen o utilizar una imagen que se haya enviado por wathsapp para disminuir el peso.")
          return
        }
      }
      return  e.target.files;
    }else{
      if(e.target.files[0].size > 2000000){
        this.presentAlert('Imagen muy pesada',"La imagen es mayor a 2 MB","Le recomendamos descargar de su pagina de facebook la imagen o utilizar una imagen que se haya enviado por wathsapp para disminuir el peso.")
        return
      }
      return  e.target.files[0];
    }
  }

  obtieneUrlImageSanitizada(file){
    var TmpPath = URL.createObjectURL(file);
    return this.dom.bypassSecurityTrustUrl(TmpPath);  
  }

  obtieneObjetoImageSanitizada(file){
    // var TmpPath = URL.createObjectURL(file);
    return this.dom.bypassSecurityTrustResourceUrl(file);  
  }

  async cargarImageDataAndroid(cantidad:number){
   let options = {
      outputType: 1,
      maximumImagesCount: cantidad
    };
    //  this.imageResponse = [];
  return await this.imagePicker.getPictures(options).then((results) => {
      // for (var i = 0; i < results.length; i++) {
        // this.imageResponse.push('data:image/jpeg;base64,' + results[i]);
       return   results //this.dataURLtoFile(results[i],"prueba.jpg");
        // this.urlimage = this.imageData
      // }
    }, (err) => { })
  }
 
  async presentAlert(header:string, subHeader:string, message:string) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['OK']
    });
    await alert.present();
  }

  async openLoader(msg) {
    const loading = await this.loadingController.create({
      message: 'Por favor espere ... ' + msg,
      // duration: 2000
    });
    await loading.present();
  }

  async closeLoading() {
    return await this.loadingController.dismiss();
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      duration: 2800
      
    });
    toast.present();
  }

  play(){
  this.analitica("radio",{'play':'radio'})
    this.browser = this.iab.create('https://node-22.zeno.fm/gf7eastn0xquv?rj-ttl=5&rj-tok=AAABcPu5uToAhNm9bGaOE_btsA', '_system', this.options)
  }

  abrir_biblia(){
    this.biblia = this.iab.create('https://www.bible.com/es/bible/149/ISA.60.RVR1960', '_blank', this.options)
  }

  abrirDocumento(url){
    console.log(url)
    this.iab.create(url, '_system').show()
    
  }

  async cargar_imagenes(identificador, imageData, carpeta:string, nombre_archivo:string, pagina_retorno:string, tipo:string, web:boolean){
    if(imageData){
      await this.openLoader('Cargando datos, espere un momento por favor.');
      for (let index = 0; index < imageData.length; index++) {
      await this.enviar_firebase(identificador, imageData[index], carpeta, nombre_archivo, pagina_retorno, index, tipo, web).then(()=>{
        this.presentToast("Cargando imagen No. " + index);
      })
      }//fin del for
      this.closeLoading()
      this.presentToast("Datos cargados exitosamente ");
      this.nav.navigateRoot(pagina_retorno)
    }else{
      this.presentAlert("imagen no cargada","","")
    }
  }

  async enviar_firebase(identificador, imageData, carpeta:string, nombre_archivo:string, pagina_retorno:string, index, tipo:string, web){
    let data:any = []

    const path = carpeta + '/'+ index + nombre_archivo + "  " + formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530') + '.jpg'
        
    if(!web){    
      await this.fireservice.cargarIMG(path, "", imageData).then(
        success =>{
          if(success){
            this.img =  this.fireservice.obtenerURL(path)
              data.push({
                "sp":"sp_principal",
                "@operacion":"I",
                "@sub_operacion":"I",
                "@tipo":tipo,
                "@id_tipo":identificador,
                "@url_image":this.img,
                "@nombre_image":path
                })
              this.sqlService.postData(data,"values").subscribe(recepcion=>{
                if(recepcion){
                  data.length = 0
                  data=[]
                  return 0
                }
              })
          }
        }
      ).catch(err => { 
        this.closeLoading();
        this.presentToast('error dio --> ' + `${err}`)
      });
    }else{
      await this.fireservice.cargarIMG(path, imageData, "").then(
        success =>{
          if(success){
            this.img =  this.fireservice.obtenerURL(path)
              data.push({
                "sp":"sp_principal",
                "@operacion":"I",
                "@sub_operacion":"I",
                "@tipo":tipo,
                "@id_tipo":identificador,
                "@url_image":this.img,
                "@nombre_image":path
                })
              this.sqlService.postData(data,"values").subscribe(recepcion=>{
                if(recepcion){
                  data.length = 0
                  data=[]
                  return 0
                }
              })
          }
        }
      ).catch(err => { 
        this.closeLoading();
        this.presentToast('error dio --> ' + `${err}`)
      });
    }
  }

  async usuario_no_logueado(){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Para ingresar a esta opción necesitas estar registrado! ¿deseas ingresar ahora?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.nav.navigateForward("/actividades")
            this.analitica('mensaje_no_logueado',{resp:'No'})
          }
        }, {
          text: 'Si',
          handler: () => {
            this.nav.navigateForward("/login")
            this.analitica('mensaje_no_logueado',{resp:'Si'})
          }
        }
      ]
    });

    await alert.present();
  }
 
  async enviarMensaje(encabezado, body, imageData){
    let img_msg:string
    const path = 'Mensajes' + '/'+ encabezado + "  " + formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530') + '.jpg'
        
      
    await this.fireservice.cargarIMG(path, "", imageData[0]).then(
      success =>{
        if(success){
        img_msg =  this.fireservice.obtenerURL(path)

          let jsondData : any = []
          jsondData.push({
            "sp":"sp_principal",
                  "@operacion":"I",
                  "@sub_operacion":"M",
                  "@tema":encabezado,
                  "@url_image":img_msg,
                  "@path_image":path,
                  "@descripcion":body
          })
          this.sqlService.postData(jsondData, "values").subscribe(resp=>{
              if(resp){
                  let httpOptions = {
                      headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'key=' + 'AAAAan1g2LY:APA91bGebFX6WcCz6S7iLYMUY0Jw71HIyBcOzIfYAFQnDm9e0vg-b9v9jHCuI4koL-SyYg1eNPbo6rqdWAcFaKRD3jfN8jvpCfa3hBuMNts_IaUhFXgr_L_D7vK4uijyfRcKV3CmnDQE'
                      })
                    }
                    const data={
                      "topic": "general",
                      "notification":{
                        "title":encabezado,
                        "body":body,
                        "sound":"default",
                        "click_action":"FCM_PLUGIN_ACTIVITY",
                        "badge":"1",
                        "icon":"../../assets/icon/favicon.png",
                        "tag":"1",
                        "image":img_msg
                      },
                      "data":{
                        "param1":"value1",
                        "param2":"value2"
                      },
                        "to":"/topics/general",
                        "priority":"high",
                    }
                    this.http.post('https://fcm.googleapis.com/fcm/send', data, httpOptions).subscribe(()=>{
                      console.log("envio")
                    })      
          }
        })
      }
    })
  }

  // suscripcionMensaje(topic, token){
  //   let datos:any = []
  //    datos = {
  //     token:token,
  //     topic:topic
  //   }
  //   this.fun.httpsCallable('subscribeToTopic')(datos).subscribe(()=>{

  //   })
  // }

  // enviarMensajeTopic( datos){
  //   let data:any = []
  //   data ={
  //     topic:datos.topic,
  //     titulo:datos.titulo,
  //     descripcion:datos.descripcion,
  //     urlimage:datos.url_image
  //   }
  //   this.fun.httpsCallable('sendToTopic')(data).subscribe(()=>{

  //   })
  // }

  obtenerApiSQL(){
    this.fireservice.obtenerLinkVivo().subscribe(data=>{
      let array = data
      if(!data){
        this.presentAlert("Parece que hay problemas con la red.","","Es necesaria la conexión a la red para que la app funcione correctamente.")
        this.nav.navigateRoot("/biblia")
      }
      array.forEach(element => {
        if (element){
          // if(this.platform.is('pwa')|| this.platform.is('mobileweb') || this.platform.is('desktop') ){
           console.log("entro aca a la PLATAFORMAf ")
            this.sqlService.base_path = element.link_api
          //   return true
          // }else{
          //   this.sqlService.base_path = element.link_api
            return true
          // }
  
        }else{
          this.sqlService.base_path = 'https://appservicerf.azurewebsites.net/api/';
          this.presentAlert("Parece que hay problemas con la red.","","Es necesaria la conexión a la red para que la app funcione correctamente.")
        }
      });
    })
  }

  requestNotificationPermission(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!('Notification' in window)) {
        reject('This browser does not support desktop notification');
      } else if (Notification.permission === 'granted') {
        resolve('Permission already granted');
      } else if (Notification.permission !== 'denied' ) {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            resolve('Permission granted');
          } else {
            reject('Permission denied');
          }
        });
      }else{
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            resolve('Permission granted');
          } else {
            reject('Permission denied');
          }
        });
      }
    });
  }





}
