import { Injectable } from '@angular/core';
import { GeneralService } from './general.service';
import { SqlService } from './sql.service';
import { SqlliteService } from './sqllite.service'; 
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from '@capacitor/core';



// const { PushNotifications } = Plugins;
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NavController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
// import { topic } from 'firebase-functions/v1/pubsub';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  token
  constructor(
    private sqlService:SqlService,
    private generalService:GeneralService,
    private sqlliteService:SqlliteService,
    private http: HttpClient,
    private platform:Platform,
    private router: Router,
    private nav:NavController
      ) { }


  //async actualizarTokenUsuario(nombre){


    // FCM.getAPNSToken().then((token)=>{
    // })

    // if(this.platform.is("ios")){
    //   this.registerPush()
    // }
    
    // this.token = await FCM.getToken().then(token => {
    //   if(this.sqlliteService.usuario){
    //     let jsondData : any = []
    //     jsondData.push({
    //       "sp":"sp_usuarios",
    //             "@operacion":"U",
    //             "@sub_operacion":"T",
    //             "@token_usuario":token,
    //             "@nombre":nombre
    //       })
    //     this.sqlService.postData(jsondData, "values").subscribe(resp=>{
    //     })
    //   }
    // });
  //}

  actualizarTokenUsuario(token):string{
      if(this.sqlliteService.IdMiembro && token){
          let jsondData : any = []
          jsondData.push({
                  "operacion":"U",
                  "sub_operacion":"T",
                  "token":token,
                  "id_usuario":this.sqlliteService.IdMiembro,
                  "sp":"sp_principal",
            })
          this.sqlService.postData(jsondData).subscribe(resp=>{
            console.log("se actualizo en db el token")
          })
        }
      
    return
    // if(!id_usuario){
    //   this.generalService.usuario_no_logueado()
    //   return
    // }
    // // Request permission to use push notifications
    // // iOS will prompt user and return if they granted permission or not
    // // Android will just grant without prompting
    // PushNotifications.requestPermission().then( result => {
    //   if (result.granted) {
    //     // Register with Apple / Google to receive push via APNS/FCM
    //     PushNotifications.register();
    //   } else {
    //     // Show some error
    //     alert("no has concedido permiso para notificaciones")
    //   }
    // });


    // // On success, we should be able to receive notifications
    //  PushNotifications.addListener('registration',
    //   (token: PushNotificationToken) => {
    //     this.token = token.value
    //    if( this.sqlliteService.usuario){
         
    //       if(this.sqlliteService.usuario){
    //           let jsondData : any = []
    //           jsondData.push({
    //                   "operacion":"U",
    //                   "sub_operacion":"T",
    //                   "token":token.value,
    //                   "id_usuario":id_usuario,
    //                   "sp":"sp_principal",
    //             })
    //           this.sqlService.postData(jsondData).subscribe(resp=>{
    //           })
    //         }
    //       }
    //   }
    // );

    // // Some issue with our setup and push will not work
    // PushNotifications.addListener('registrationError',
    //   (error: any) => {
    //     alert('Error en registro de token: ' + JSON.stringify(error));
    //   }
    // );

    // // Show us the notification payload if the app is open on our device
    // PushNotifications.addListener('pushNotificationReceived',
    //   (notification: PushNotification) => {
    //     alert('Nuevo mensaje recibido: ' + JSON.stringify(notification));
    //   }
    // );

    // // Method called when tapping on a notification
    // PushNotifications.addListener('pushNotificationActionPerformed',
    //   (notification: PushNotificationActionPerformed) => {
    //     this.nav.navigateRoot('/folder/mensajes');
    //   }
    // );

    
  }

  subscribirTopic(token:string,topico:string){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'key=' + 'AAAAan1g2LY:APA91bGebFX6WcCz6S7iLYMUY0Jw71HIyBcOzIfYAFQnDm9e0vg-b9v9jHCuI4koL-SyYg1eNPbo6rqdWAcFaKRD3jfN8jvpCfa3hBuMNts_IaUhFXgr_L_D7vK4uijyfRcKV3CmnDQE'
      })
    }

    const data={
      "registration_tokens": [token],
      "to": "/topics/"+topico
    }
    this.http.post('https://iid.googleapis.com/iid/v1:batchAdd', data, httpOptions).subscribe(()=>{
      console.log("Suscrito exitosamente.")
    })  
  }

  enviarMensaje(encabezado, body, token, urlimage?){
    if(!urlimage){
      urlimage = "https://firebasestorage.googleapis.com/v0/b/rf-db-eaa95.appspot.com/o/512.png?alt=media&token=f12850a4-e89e-40d8-baa7-ac1a8196b361"
    }
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'key=' + 'AAAAan1g2LY:APA91bGebFX6WcCz6S7iLYMUY0Jw71HIyBcOzIfYAFQnDm9e0vg-b9v9jHCuI4koL-SyYg1eNPbo6rqdWAcFaKRD3jfN8jvpCfa3hBuMNts_IaUhFXgr_L_D7vK4uijyfRcKV3CmnDQE'
      })
    }
    const data={
      "notification":{
        "title":encabezado,
        "body":body,
        "sound":"default",
        "click_action":"FCM_PLUGIN_ACTIVITY",
        "badge":"1",
        "icon":"./../../assets/icon/favicon.png",
        "tag":"1",
        "image":urlimage
      },
      "data":{
        "param1":"value1",
        "param2":"value2"
      },
        "to":token,
        "priority":"high",
    }
    this.http.post('https://fcm.googleapis.com/fcm/send', data, httpOptions).subscribe(()=>{
      this.generalService.presentToast("Mensaje enviado exitosamente.")
    })      
  }

  enviarMensaje_topic(topic, encabezado, body){
    
                  let httpOptions = {
                      headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'key=' + 'AAAAan1g2LY:APA91bGebFX6WcCz6S7iLYMUY0Jw71HIyBcOzIfYAFQnDm9e0vg-b9v9jHCuI4koL-SyYg1eNPbo6rqdWAcFaKRD3jfN8jvpCfa3hBuMNts_IaUhFXgr_L_D7vK4uijyfRcKV3CmnDQE'
                      })
                    }
                    const data={
                      "topic": "general",
                      "notification":{
                        "title":encabezado,
                        "body":body,
                        "sound":"default",
                        "click_action":"FCM_PLUGIN_ACTIVITY",
                        "badge":"1",
                        "icon":"../../assets/icon/favicon.png",
                        "tag":"1",
                        "image":"../../assets/icon/favicon.png"
                      },
                      "data":{
                        "param1":"value1",
                        "param2":"value2"
                      },
                        "to":"/topics/" + topic,
                        "priority":"high",
                    }
                    this.http.post('https://fcm.googleapis.com/fcm/send', data, httpOptions).subscribe(()=>{
                      console.log("envio")
                    })      
  }

  

}
